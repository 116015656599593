import React, { useContext } from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import {
  CartContext,
  useAddItemToCart,
  useToggleCart,
} from '../contexts/CartContext';
import { formatCurrency } from '../utils';
import Spinner from '../icons/Spinner';
import styles from './ShopProduct/styles.module.scss';

const VariantItem = ({ priceRangeV2, variant, isAvailable }) => {
  const {
    cart: { isAdding, currentLineItem },
  } = useContext(CartContext);

  const addItemToCart = useAddItemToCart();
  const toggleCart = useToggleCart();

  async function handleAddToCart() {
    await addItemToCart(variant.storefrontId, 1);
    toggleCart(true);
  }

  const currencyCode = priceRangeV2.maxVariantPrice.currencyCode;
  const price = variant.presentmentPrices.find(
    presentmentPrice => presentmentPrice.price.currencyCode === currencyCode
  ).price.amount;

  return (
    <div key={variant.storefrontId}>
      <p className={styles.price}>{formatCurrency(price, currencyCode)}</p>
      <div className={cx('d-flex', styles.add)}>
        <button className="btn" onClick={handleAddToCart}>
          {isAvailable ? 'Add to Cart' : 'Pre-order'}
        </button>
        {isAdding && variant.storefrontId === currentLineItem && (
          <Spinner className={styles.spinner} />
        )}
      </div>
    </div>
  );
};

export default VariantItem;
